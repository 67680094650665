import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'

import './assets/scss/style.scss'

import './assets/scss/custom.css'

import Spinner from './views/spinner/Spinner'

const App = lazy(
    () =>
        new Promise((resolve) => {
            setTimeout(() => resolve(import('./app')), 0)
        }),
)

if (['homolog', 'prod'].includes(process.env.REACT_APP_ENVIRONMENT)) {
    disableReactDevTools()
}

ReactDOM.render(
    <Suspense fallback={<Spinner />}>
        <App />
    </Suspense>,
    document.getElementById('root'),
)
